import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CognitoService } from '../services/cognito.service';

import * as _ from 'underscore';
import { ConfigService } from '../services/config.service';
import { CommonScreenComponent } from '../common/common-screen.component';

@Component({
    selector: 'app-sign-in-callback',
    templateUrl: './sign-in-callback.component.html',
    styleUrls: ['./sign-in-callback.component.scss']
})
export class SignInCallbackComponent extends CommonScreenComponent {


    @ViewChild('commonComponent')
    private commonComponent!: CommonScreenComponent;


    constructor (
        protected cognitoService: CognitoService,
        protected configService: ConfigService,
        protected activatedRoute: ActivatedRoute
    ) {
        super(cognitoService,configService,activatedRoute);
        _.bindAll(this, 'logMessageSignIn');
    }

    afterNgOnInit() {
        this.commonComponent.loading = true;
        this.commonComponent.disableButtons = true;
        this.commonComponent.loadingText = "Processing ...";

        this.cognitoService.init().subscribe(() => {
            let auth = this.cognitoService.getAuthInstance(this.logMessageSignIn);
            var curUrl = window.location.href;

            auth.parseCognitoWebResponse(curUrl);

        }, (error) => {
            console.log(`init : error : ${error}`);
        })
    }


    logMessageSignIn(message: string, isError: boolean = false) {
        if (isError) {
                this.commonComponent.loading = false;
                this.commonComponent.disableButtons = false;
                this.commonComponent.currentErrorMessage = message;
            } else {
                this.currentErrorMessage = "";
            }
    }
}

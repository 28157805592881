import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../services/cognito.service';
import { ConfigService } from '../services/config.service';
import { IAuthParams, ICognitoConfig } from '../services/interfaces/config.interfaces';
import * as _ from "underscore";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-common-screen',
    templateUrl: './common-screen.component.html',
    styleUrls: ['./common-screen.component.scss']
})
export class CommonScreenComponent implements OnInit {

    email: string = "";
    isProduction: boolean = false;
    authEnvironments: Array<IAuthParams> = [];
    currentErrorMessage: string = "";
    currentVersion: string = "";
    disableButtons: boolean = true;
    loading: boolean = false;
    loginLoadingTimeout = 10000;
    loadingText = "Authenticating ...";

    constructor(
        protected cognitoService: CognitoService,
        protected configService: ConfigService,
        protected activatedRoute: ActivatedRoute
    ) {
        _.bindAll(this, 'logMessage');
    }

    ngOnInit(): void {
        this.configService.getConfig().subscribe((item: ICognitoConfig) => {
            this.authEnvironments = item.cognitoPools;
            this.isProduction = item.isProduction;
            this.currentVersion = item.version;

            if (item.loginRedirectTimeoutMs && item.loginRedirectTimeoutMs.length > 0) {
                this.loginLoadingTimeout = parseInt(item.loginRedirectTimeoutMs);
            }
            this.afterNgOnInit();
        }, (error) => {
            console.log(`configService returned : ${error}`);
        });
    }

    afterNgOnInit(): void {
    }

    hasErrors() {
        return this.currentErrorMessage.length > 0;
    }

    showBlankAlert() {
        return !this.hasErrors() && !this.loading;
    }

    signInByEnvName(envName?: string) {
        this.disableButtons = true;
        this.loading = true;
        this.currentErrorMessage = "";

        let authConfig: IAuthParams | undefined;

        if (envName != null) {
            authConfig = this.authEnvironments.find((element: IAuthParams) => element.envName === envName);
            if (!authConfig) {
                this.disableButtons = false;
                this.loading = false;
                this.currentErrorMessage = "Sorry, your domain name is not registered";
                return;
            }
        }
        else {
            console.log("its an email login");
            //it is an email login
            //Do logic to figure out what which configuration to use based on email domain
            authConfig = this.authEnvironments[0];
        }

        this.logMessage(`Connecting to Cognito`);

        this.cognitoService.createAuth(authConfig, this.logMessage);
        this.cognitoService.login();
    }

    logMessage(message: string, isError: boolean = false) {
        if (isError) {
            this.loading = false;
            this.disableButtons = false;
            this.currentErrorMessage = message;
        } else {
            this.currentErrorMessage = "";
        }
    }

}

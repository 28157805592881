import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ICognitoConfig } from './interfaces/config.interfaces';


@Injectable()
export class ConfigService {
    constructor(private http: HttpClient) { }

    getConfig(): Observable<ICognitoConfig> {
        return this.http.get(`./assets/config/cognito-config.json`)
            .pipe(
                map((config: unknown) => <ICognitoConfig>config)
                ,
                catchError((error) => {
                    console.log(`ConfigService ; error ; ${error}`);
                    return throwError(error)
                })
            )
    }

}

import { Component, ViewChild } from '@angular/core';
import * as _ from "underscore";
import { CommonScreenComponent } from '../common/common-screen.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends CommonScreenComponent {

    @ViewChild('commonComponent')
    public commonComponent!: CommonScreenComponent;

    afterNgOnInit(): void {
        this.commonComponent.disableButtons = false;
    }
}

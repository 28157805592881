import { Injectable } from '@angular/core';
import {
    CognitoUserPool,
    ICognitoUserPoolData,
    CognitoUser,
} from 'amazon-cognito-identity-js';
import {
    CognitoAuth,
    CognitoIdToken,
} from 'amazon-cognito-auth-js';
import * as AWS from 'aws-sdk';
import { IAuthParams, ICognitoConfig } from './interfaces/config.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { STS } from 'aws-sdk';
import { ConfigService } from './config.service';
import { forkJoin, Observable, of } from 'rxjs';
import { NumberOfAutoScalingGroups } from 'aws-sdk/clients/autoscaling';

export interface IAuthConfigData {
    environmentName: string;
    quickSightLoginUrl?: string;
    timeStamp?: NumberOfAutoScalingGroups;
}

@Injectable()
export class CognitoService {
    auth!: CognitoAuth;

    userPool: CognitoUserPool | undefined;

    authEnvironments: Array<IAuthParams> = [];

    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {
        this.configService.getConfig().subscribe((item: ICognitoConfig) => {
            this.authEnvironments = item.cognitoPools;
        });
    }

    init(): Observable<boolean> {
        let result = of(true);
        let config = this.configService.getConfig();

        return forkJoin([result, config], () => {
        });
    }

    createAuth(authConfig: IAuthParams, messageCallback: (message: string, isError?: boolean) => void): void {

        console.log("Creating Auth");
        let authConfigData: IAuthConfigData = {
            environmentName: authConfig.envName
        }
        localStorage.setItem('authConfigEnvironment', JSON.stringify(authConfigData));

        AWS.config.update({
            region: authConfig.identityPoolRegion,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: authConfig.identityPool,
            }),
            accessKeyId: 'null',
            secretAccessKey: 'null',
        });

        console.log("AWS config update successful")

        this.auth = new CognitoAuth(authConfig.authData);

        this.auth.userhandler = {
            onSuccess: (authSession): void => {
                messageCallback(`Cognito initialized`)

                let id_token: string = this.auth
                    .getSignInUserSession()
                    .getIdToken()
                    .getJwtToken();
                let id_token_object = new CognitoIdToken(id_token);

                let getIdParams: any = {
                    IdentityPoolId: authConfig.identityPool,
                    Logins: {},
                };

                getIdParams.Logins[
                    'cognito-idp.' +
                    authConfig.userPoolRegion +
                    '.amazonaws.com/' +
                    authConfig.poolId
                ] = id_token;

                var cognitoIdentity: AWS.CognitoIdentity = new AWS.CognitoIdentity();

                // external call with callback.

                cognitoIdentity.getId(getIdParams, (errorGetId, getIdData) => {
                    messageCallback(`Cognito identity found`)
                    // Call GetId
                    if (errorGetId) {
                        messageCallback(errorGetId.message, true);
                        console.log("Error with cognito identity" +  errorGetId.message);
                    }
                    else {
                        console.log("Cognito identity found")

                        var getOpenIdTokenParams: any = {
                            IdentityId: getIdData.IdentityId,
                            Logins: {},
                        };

                        getOpenIdTokenParams.Logins[
                            'cognito-idp.' +
                            authConfig.userPoolRegion +
                            '.amazonaws.com/' +
                            authConfig.poolId
                        ] = id_token;

                        // external call with callback

                        cognitoIdentity.getOpenIdToken(
                            getOpenIdTokenParams,
                            (errorGetOpenIdToken, getOpenIdTokenData) => {
                                messageCallback(`Cognito token returned`);



                                // Call GetOpenIdToken
                                if (errorGetOpenIdToken) {
                                    messageCallback(errorGetOpenIdToken.message, true);
                                    // this.logout(errorGetOpenIdToken.stack);
                                }
                                // an error occurred
                                else {
                                    let decodedToken: any = id_token_object.decodePayload();
                                    var assumeRoleWithWebIdentityParams: any = {
                                        RoleArn: authConfig.authRoleArn, // role we wish to assume
                                        RoleSessionName: decodedToken['email'],
                                        WebIdentityToken: getOpenIdTokenData.Token, // previous ID token from Idenity provider
                                    };

                                    var sts: STS = new AWS.STS();

                                    // external call with callback.
                                    sts.assumeRoleWithWebIdentity(
                                        assumeRoleWithWebIdentityParams,
                                        (errorAssumeRole, assumeRoleWithWebIdentityData) => {
                                            messageCallback(`Cognito role identified`)

                                            // Call AssumeRoleWithWebIdentity
                                            if (errorAssumeRole) {
                                                messageCallback(errorAssumeRole.message, true);
                                            }
                                            // an error occurred
                                            else {
                                                // sts service response
                                                var creds = {
                                                    sessionId:
                                                        assumeRoleWithWebIdentityData.Credentials
                                                            ?.AccessKeyId,
                                                    sessionKey:
                                                        assumeRoleWithWebIdentityData.Credentials
                                                            ?.SecretAccessKey,
                                                    sessionToken:
                                                        assumeRoleWithWebIdentityData.Credentials
                                                            ?.SessionToken,
                                                };

                                                var uri =
                                                    'https://signin.aws.amazon.com/federation?Action=getSigninToken&SessionDuration=43200&Session=' +
                                                    encodeURIComponent(JSON.stringify(creds));

                                                // lambda function will use this url

                                                let headers = new HttpHeaders().set(
                                                    'Authorization',
                                                    id_token
                                                );

                                                // subscribing here

                                                // this does the update / add to cognito
                                                this.http
                                                    .post(authConfig.endpoint, uri, { headers })
                                                    .pipe()
                                                    .subscribe({
                                                        next: (response: any) => {

                                                            // sign out
                                                            // can do a get NO, must navigate the browser here.

                                                            let localStorageItem = this.getLocalStorage();


                                                            var logoutUrl: string = `https://${authConfig.appDomain}/logout?client_id=${authConfig.clientId}&logout_uri=${authConfig.authData.RedirectUriSignOut}`;

                                                            // store in localstorage

                                                            // redirect to QS
                                                            var quickSightSSOURL: string =
                                                                'https://signin.aws.amazon.com/federation?Action=login&Issuer=' +
                                                                encodeURIComponent(
                                                                    'https://' + window.location.hostname
                                                                )
                                                                +
                                                                '&Destination=' +
                                                                encodeURIComponent(
                                                                    'https://quicksight.aws.amazon.com/'
                                                                )
                                                                +
                                                                '&SigninToken=' + response.SigninToken;
                                                            // let logoutRedirect: string = `${logoutUrl}#qs_url=${quickSightSSOURL}`;
                                                            let logoutRedirect: string = `${logoutUrl}`;

                                                            localStorageItem.quickSightLoginUrl = quickSightSSOURL;
                                                            localStorageItem.timeStamp = new Date().valueOf();

                                                            localStorage.setItem('authConfigEnvironment', JSON.stringify(localStorageItem));

                                                            this.auth.signOut();
                                                            window.location.href = logoutRedirect;

                                                        },
                                                        error: (error) => {
                                                            messageCallback("Authentication failure.  Please contact your system Administrator", true);
                                                        },
                                                    });
                                            }
                                        }
                                    );
                                }
                            }
                        );
                    }
                });
            },

            onFailure: (error): void => {
                setTimeout(() => {
                    this.logout(error);
                }, 3000)

            },
        };
    }

    /**
     * Check's if the user is authenticated - used by the Guard.
     */
    authenticated(): CognitoUser | null {
        const COGNITO_CONFIGS: ICognitoUserPoolData = {
            UserPoolId: this.getAuthConfig().poolId,
            ClientId: this.getAuthConfig().clientId,
        };
        this.userPool = new CognitoUserPool(COGNITO_CONFIGS);
        // behind the scene getCurrentUser looks for the user on the local storage.
        return this.userPool.getCurrentUser();
    }

    login(): void {
        // The default response_type is "token", uncomment the next line will make it be "code".
        //CognitoService.auth.useCodeGrantFlow();
        // use theis for clients that you don't trust.
        // lambda in cognito does most of the work.
        // might be good to investigate if this is needed.
        this.auth.getSession();
    }

    logout(error: any): void {
        console.log(error);
        this.auth.signOut();
    }

    getIdToken(): string {
        return this.auth.getSignInUserSession().getIdToken().getJwtToken();
    }

    getAuthConfig(): IAuthParams {
        let localStorageData = this.getLocalStorage();

        let envName = localStorageData.environmentName;
        let authConfig: IAuthParams | undefined = this.authEnvironments.find(
            (element: IAuthParams) => element.envName === envName
        );
        return authConfig as IAuthParams;
    }

    getLocalStorage(): IAuthConfigData {
        let localStorageItem = localStorage.getItem('authConfigEnvironment');

        if (localStorageItem) {
            return JSON.parse(localStorageItem);
        } else {
            return { environmentName: "" }
        }

    }

    getAuthInstance(logCallback: (message: string) => void): CognitoAuth {
        // If an auth has already been created the log call back will be the method from common-screen-component
        // else it will be the callback from sin-in callback-component
        if (!this.auth) {
            let cognitoParams = this.getAuthConfig();
            this.createAuth(cognitoParams, logCallback);
        }
        return this.auth;
    }
}

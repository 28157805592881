<!--
<div class="background-image-full d-flex align-items-baseline">
    <div class="compass-page-text ">Compass</div>
</div>

<div class="full-size-container d-flex align-items-center justify-content-center vix-background-colour">

    <div class="d-flex flex-column align-items-center left-content-panel">
        <div class="left-content-top-spacer">&nbsp;</div>
        <div class="vix-logo-image ">
            <img src="/assets/Vix-logo-transparent.png" class="img-fluid" />
        </div>
        <div class="signin-text">
            Sign in to Compass.
        </div>
        <div *ngIf="isProduction" id="signInEmailContainer">
            <form #loginEmailForm="ngForm" role="form" (ngSubmit)="signInByEnvName()">
                <div class="input-email-control">
                    <input id="signInEmail" type="email" class="form-control input-control-style"
                        placeholder="Email or username" [(ngModel)]="email" name="email" />
                </div>
                <div class="input-email-control">
                    <div id="startButtons" class="center-align">
                        <button class="btn btn-primary btn-full-width" id="signInButton" title="Sign in">Next</button>
                    </div>
                </div>
            </form>

        </div>

        <div *ngIf="!isProduction">

            <div *ngFor="let item of authEnvironments" class="input-email-control">
                <div id="startButtons" class="center-align">
                    <button class="btn btn-primary btn-full-width" [disabled]="disableButtons"
                        (click)="signInByEnvName(item.envName)">{{item.envName}}</button>
                </div>
            </div>

        </div>
        <div class="screen-messages container-full-width">
            <div *ngIf="showBlankAlert()">
                <div class="alert alert-invisible" role="alert">
                    &nbsp;
                </div>

            </div>
            <div *ngIf="loading">
                <div class="alert alert-invisible" role="alert">
                    &nbsp;
                    <div class="app-loading">
                        <svg class="spinner" viewBox="25 25 50 50">
                            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10" />
                        </svg>
                    </div>

                </div>
            </div>
            <div *ngIf="hasErrors()">
                <div class="alert alert-danger" role="alert">
                    {{currentErrorMessage}}
                </div>
            </div>
        </div>
        <div class="horizontal-rule">
            <hr />

        </div>
        <div class="version-text">{{currentVersion}}</div>
        <div class="bottom-spacer">&nbsp;</div>
    </div>
</div> -->

<!-- ------------------

NEW

---------------- -->


<div class="background-image-full d-flex flex-column align-items-baseline">
    <div class="compass-page-padding ">
        <div class="compass-page-text-small">&nbsp;</div>
        <div class="compass-page-text-large">Compass</div>
    </div>
</div>

<div class="full-size-container d-flex xalign-items-center justify-content-center vix-background-colour">
    <div class="d-flex flex-column align-items-center left-content-panel">
        <div class="left-content-top-spacer">&nbsp;</div>
        <div class="vix-logo-image ">
            <div class="vix-logo-image-background"></div>
        </div>
        <div class="signin-text">
            Compass
        </div>
        <div class="main-loading-content d-flex flex-column align-items-center">
            <div *ngIf="loading" class="loading-text">
                {{loadingText}}
            </div>
            <div *ngIf="isProduction && !loading" id="signInEmailContainer">
                <form #loginEmailForm="ngForm" role="form" (ngSubmit)="signInByEnvName()">
                    <div><b>Sign In</b></div>
                    <div class="input-email-control">
                        <input id="signInEmail" type="email" class="form-control input-control-style"
                            placeholder="Email or username" [(ngModel)]="email" name="email" />
                    </div>
                    <div class="input-email-control">
                        <div id="startButtons" class="center-align">
                            <button class="btn btn-primary btn-full-width" id="signInButton" title="Sign in">Next</button>
                        </div>
                    </div>
                </form>

            </div>

            <div *ngIf="!isProduction && !loading">
                <div><b>Sign In</b></div>
                <div *ngFor="let item of authEnvironments" class="input-email-control">
                    <div id="startButtons" class="center-align">
                        <button class="btn btn-primary btn-full-width" [disabled]="disableButtons"
                            (click)="signInByEnvName(item.envName)">{{item.envName}}</button>
                    </div>
                </div>

            </div>
            <div *ngIf="loading" class="loading-spinner-container" role="alert">
                &nbsp;
                <div class="app-loading">
                    <svg class="spinner" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10" />
                    </svg>
                </div>

            </div>
            <div *ngIf="!loading" class="loading-spinner-container" role="alert">
                <!-- this is to prevent the screen resizing when the spinner activates -->
                &nbsp;
            </div>
            <div *ngIf="hasErrors()">
                <div class="alert alert-danger" role="alert">
                    {{currentErrorMessage}}
                </div>
            </div>
            <div class="screen-messages container-full-width">
                <!-- forgot password here -->
                <!-- <div class="input-email-control">
                    <div id="forgotPasswordButton" class="center-align">
                        <button class="btn btn-full-width" id="forgotPasswordButton" title="Forgot Password" (click)="onForgotPassword()">Forgot Password ?</button>
                    </div>
                </div> -->
            </div>
            <div class="horizontal-rule">
                <hr />

            </div>


                <div class="version-text">{{currentVersion}}</div>
                <div class="bottom-spacer">&nbsp;</div>

        </div>
    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonScreenComponent } from '../common/common-screen.component';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends CommonScreenComponent {


    @ViewChild('commonComponent')
    private commonComponent!: CommonScreenComponent;

    afterNgOnInit(): void {
        console.log(`afterNgOnInit : logoutComponent`)

        this.commonComponent.loading = true;
        this.commonComponent.disableButtons = true;
        this.commonComponent.loadingText = "Signing you in ...";

        let localStorageItem = this.cognitoService.getLocalStorage();

        let timeNow = new Date().valueOf();
        let validLogin = false;

        if (localStorageItem.timeStamp) {
            console.log(`timeNow : ${timeNow}`);
            console.log(`localsT time : ${localStorageItem.timeStamp}`)
            let timeDiff = timeNow - localStorageItem.timeStamp.valueOf();
            console.log(`time diff = ${timeDiff}`);
            console.log(`loginLoadingTimeout : ${this.loginLoadingTimeout}`);

            if (timeDiff < this.loginLoadingTimeout) {
                if (localStorageItem.quickSightLoginUrl) {
                    validLogin = true;
                    window.location.href = localStorageItem.quickSightLoginUrl;
                }
            }
        }
        if (!validLogin) {
            this.commonComponent.loading = false;
            this.commonComponent.logMessage('You have been logged out.', true);
        }

        // this.activatedRoute.fragment.subscribe((fragment: string) => {

        //     if (fragment && fragment.startsWith("qs_url=")) {
        //         let qs_url_fragment = fragment.substr(7);
        //         window.location.href = qs_url_fragment;
        //     } else {
        //         this.commonComponent.loading = false;
        //         this.commonComponent.logMessage('You have been logged out.', true);
        //     }

        // });

    }


}
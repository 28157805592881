import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CognitoService } from './services/cognito.service';
import { HttpClientModule } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { CommonScreenComponent } from './common/common-screen.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SignInCallbackComponent } from './sign-in-callback/sign-in-callback.component';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        AppComponent,
        CommonScreenComponent,
        LoginComponent,
        LogoutComponent,
        SignInCallbackComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule
    ],
    providers: [
        CognitoService,
        ConfigService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
